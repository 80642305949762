
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import { Table } from "@/utils/decorator";
import { emptyToLine } from "@/utils/common";
import dayjs from "dayjs";
const campaign = namespace("campaign");
const base = namespace("base");

@Component({
  components: { FileUpload }
})
@Table("loadListData")
export default class CampaignAnalysis extends Vue {
  @campaign.Action queryCampaignJoinList;
  @campaign.Action queryCampaignStatistic;
  @campaign.Action queryCampaignDetail;
  @base.Action getDictionaryList;
  emptyToLine = emptyToLine;
  dayjs = dayjs;
  get breadData() {
    return [
      { name: this.campaignTypeEnum[this.campaignType], path: this.campaginPrevPathEnum[this.campaignType] },
      {
        name: this.$t("v210801.activity-data-analysis")
      }
    ];
  }
  searchForm: any = {
    customerName: "",
    customerMobile: "",
    detailStatus: ""
  };
  get searchList() {
    return [
      {
        label: this.$t("finance.guest-names"),
        type: "input",
        value: "",
        prop: "customerName"
      },
      {
        label: this.$t("finance.mobile"),
        type: "input",
        value: "",
        prop: "customerMobile"
      },
      {
        label: this.$t("v210801.associated-coupon-status"),
        type: "select",
        selectList: this.relevancedetailStatusList,
        value: "",
        prop: "detailStatus"
      }
    ];
  }
  campaginPrevPathEnum: any = {
    lottery: "/marketing/campaign/lottery",
    collect: "/marketing/campaign/collect-customer"
  };
  get campaignTypeEnum() {
    return {
      lottery: this.$t("v210801.lucky-draw"),
      collect: this.$t("v210801.customer-accumulation-activities")
    };
  }
  get id() {
    return this.$route.params.id;
  }
  get campaignType() {
    return this.$route.query.campaignType as string;
  }
  created() {
    this.init();
  }
  campaignCustomerList: any = [];
  relevancedetailStatusList: any = [];
  statisticData: any = [{}, {}, {}];
  get lookToReceiveRate() {
    try {
      if (Number(this.statisticData[0].count) && Number(this.statisticData[1].count)) {
        return ((this.statisticData[1].count / this.statisticData[0].count) * 100).toFixed(2);
      } else {
        return "0";
      }
    } catch {
      return "0";
    }
  }
  get ReceiveToUsedRate() {
    try {
      if (Number(this.statisticData[1].count) && Number(this.statisticData[2].count)) {
        return ((this.statisticData[2].count / this.statisticData[1].count) * 100).toFixed(2);
      } else {
        return "0";
      }
    } catch {
      return "0";
    }
  }
  get lookToUsedRate() {
    try {
      if (Number(this.statisticData[0].count) && Number(this.statisticData[2].count)) {
        return ((this.statisticData[2].count / this.statisticData[0].count) * 100).toFixed(2);
      } else {
        return "0";
      }
    } catch {
      return "0";
    }
  }
  campaignData: any = {};
  init() {
    this.loadListData();
    this.getDictionaryList("campaign_detail_sc_status").then(data => {
      this.relevancedetailStatusList = data;
    });
    this.queryCampaignStatistic({
      campaignCode: this.id
    }).then(data => {
      this.statisticData = data.data.statisticArray;
    });
    this.queryCampaignDetail({ campaignCode: this.id }).then(data => {
      this.campaignData = data.data;
    });
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryCampaignJoinList({
      campaignCode: this.id,
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.campaignCustomerList = data.data.list;
      return data;
    });
  }
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
}
